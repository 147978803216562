/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * */
import * as React from 'react';
import { Provider, connect } from 'react-redux';
import { store } from '@opengeoweb/core/store';
import {
  MapViewConnect,
  mapTypes,
  mapActions,
  mapSelectors,
} from '@opengeoweb/core';

import ConnectedLayerToggleComponent from './LayerToggleComponent';
import ConnectedAnimationComponent from './AnimationComponent';

const baseLayerArcGisCanvas = {
  service:
    '//services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/',
  id: 'base-layer-3',
  name: 'arcGisCanvas',
  type: 'twms',
  enabled: true,
  layerType: mapTypes.LayerType.baseLayer,
};

const overLayer = {
  service: 'https://geoservices.knmi.nl/cgi-bin/worldmaps.cgi?',
  name: 'ne_10m_admin_0_countries_simplified',
  format: 'image/png',
  enabled: true,
  id: 'base-layer-1',
  layerType: mapTypes.LayerType.overLayer,
};

const satelliteLayer = {
  service: 'https://eumetview.eumetsat.int/geoserv/wms?',
  name: 'meteosat:msg_eview',
  format: 'image/jpeg',
  enabled: false,
  style: 'default',
  id: 'satellite-layer-1',
  layerType: mapTypes.LayerType.mapLayer,
};

const radarLayer = {
  service: 'https://openwms.fmi.fi/geoserver/wms?',
  name: 'Radar:suomi_dbz_eureffin',
  format: 'image/png',
  enabled: true,
  style: '',
  id: 'radar-layer-1',
  layerType: mapTypes.LayerType.mapLayer,
};

const lightningLayer = {
  service: 'https://geoserver.apps.meteo.fi/geoserver/observation/wms?',
  name: 'lightning',
  format: 'image/png8',
  enabled: true,
  style: '',
  id: 'lightning-layer-1',
  layerType: mapTypes.LayerType.mapLayer,
};

const observationLayer = {
  service: 'https://geoserver.apps.meteo.fi/geoserver/observation/wms?',
  name: 'air_temperature',
  format: 'image/png8',
  enabled: true,
  style: '',
  id: 'observation-layer-1',
  layerType: mapTypes.LayerType.mapLayer,
};

const FinalndBbox = {
  srs: 'EPSG:3857',
  bbox: {
    left: 1372197.5318,
    bottom: 8022830.4888,
    right: 4446800.5575,
    top: 11192826.9259,
  },
};

interface RadarProps {
  mapId: string;
  setBaseLayers: typeof mapActions.setBaseLayers;
  setLayers: typeof mapActions.setLayers;
  setBbox: typeof mapActions.setBbox;
  currentBbox: mapTypes.Bbox;
  layers: typeof mapSelectors.getMapLayers;
}

const Radar: React.FC<RadarProps> = ({
  mapId,
  setBaseLayers,
  setLayers,
  setBbox,
  layers,
  currentBbox,
}: RadarProps) => {
  React.useEffect(() => {
    setBaseLayers({ mapId, layers: [baseLayerArcGisCanvas, overLayer] });
    setLayers({
      mapId,
      layers: [observationLayer, lightningLayer, radarLayer, satelliteLayer],
    });
    setBbox({
      mapId,
      srs: FinalndBbox.srs,
      bbox: FinalndBbox.bbox,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <MapViewConnect mapId={mapId} />
    </>
  );
};

const ConnectedRadar = connect(
  (state, props) => ({
    currentBbox: mapSelectors.getBbox(state, props.mapId),
    layers: mapSelectors.getMapLayers(state, props.mapId),
  }),
  {
    setLayers: mapActions.setLayers,
    setBaseLayers: mapActions.setBaseLayers,
    setBbox: mapActions.setBbox,
  },
)(Radar);

const mapId = 'map_1';
const Body: React.FC = () => (
  <Provider store={store}>
    <ConnectedRadar mapId={mapId} />
    <ConnectedLayerToggleComponent mapId="map_1" />
    <ConnectedAnimationComponent mapId="map_1" />
  </Provider>
);
export default Body;
try {
    // @ts-ignore
    Body.displayName = "Body";
    // @ts-ignore
    Body.__docgenInfo = { "description": "", "displayName": "Body", "props": {} };
    // @ts-ignore
    if (typeof STORYBOOK_REACT_CLASSES !== "undefined")
        // @ts-ignore
        STORYBOOK_REACT_CLASSES["src/components/Body.tsx#Body"] = { docgenInfo: Body.__docgenInfo, name: "Body", path: "src/components/Body.tsx#Body" };
}
catch (__react_docgen_typescript_loader_error) { }