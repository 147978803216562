import * as React from 'react';
import { connect } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import RadarIcon from '@material-ui/icons/TrackChanges';
import SatelliteIcon from '@material-ui/icons/Satellite';
import FlashIcon from '@material-ui/icons/FlashOn';
import ObservationIcon from '@material-ui/icons/Place';

import { mapActions, mapSelectors } from '@opengeoweb/core';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

interface LayerToggleComponentProps {
  layerChangeEnabled: typeof mapActions.layerChangeEnabled;
  layers: typeof mapSelectors.getMapLayers;
}

const LayerToggleComponent: React.FC<LayerToggleComponentProps> = ({
  layers,
  layerChangeEnabled,
}: LayerToggleComponentProps) => {
  // const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="toolbar navbar noselect">
      <ThemeProvider theme={theme}>
        <BottomNavigation
       // value={value}
       // onChange={(event, newValue) => {
       //   setValue(newValue);
       // }}
          showLabels
        >
          <BottomNavigationAction
            aria-controls="simple-menu"
            aria-haspopup="true"
            label="Satelliitti"
            icon={<SatelliteIcon />}
            onClick={(): void => {
              layerChangeEnabled({
                layerId: layers[3].id,
                enabled: !layers[3].enabled,
              });
            }}
          />
          <Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
>
  <MenuItem onClick={handleClose}>Profile</MenuItem>
  <MenuItem onClick={handleClose}>My account</MenuItem>
  <MenuItem onClick={handleClose}>Logout</MenuItem>
</Menu>
          <BottomNavigationAction
            label="Säätutka"
            icon={<RadarIcon />}
            onClick={(): void => {
              layerChangeEnabled({
                layerId: layers[2].id,
                enabled: !layers[2].enabled,
              });
            }}
          />
          <BottomNavigationAction
            label="Salamat"
            icon={<FlashIcon />}
            onClick={(): void => {
              layerChangeEnabled({
                layerId: layers[1].id,
                enabled: !layers[1].enabled,
              });
            }}
          />
          <BottomNavigationAction
            label="Havainto"
            icon={<ObservationIcon />}
            onClick={(): void => {
              layerChangeEnabled({
                layerId: layers[0].id,
                enabled: !layers[0].enabled,
              });
            }}
          />
        </BottomNavigation>
      </ThemeProvider>
    </div>
  );
};

const ConnectedLayerToggleComponent = connect(
  (state, props) => ({
    layers: mapSelectors.getMapLayers(state, props.mapId),
  }),
  {
    layerChangeEnabled: mapActions.layerChangeEnabled,
  },
)(LayerToggleComponent);

export default ConnectedLayerToggleComponent;
