import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, IconButton, createMuiTheme, ThemeProvider } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { mapActions, mapSelectors } from '@opengeoweb/core';

moment.locale('fi');

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

interface AnimationComponentProps {
  mapId: string;
  startAnimation: typeof mapActions.mapStartAnimation;
  stopAnimation: typeof mapActions.mapStopAnimation;
  layers: typeof mapSelectors.getMapLayers;
  isAnimating?: boolean;
}

const AnimationComponent: React.FC<AnimationComponentProps> = ({
  startAnimation,
  stopAnimation,
  mapId,
  layers,
  isAnimating,
}: AnimationComponentProps) => {
  const layer = layers[2];
  const [interval, setInterval] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const play = (value = 600): void => {
    setInterval(value);
    const step = (!value ? 600 : value) / 60;
    const now = moment.utc().subtract(5, 'm');

    const nowrounded = moment(now)
      .minute(Math.floor(moment(now).minute() / step) * step)
      .second(0);

    const start = moment(nowrounded).subtract(20 * step, 'm');
    const end = moment(nowrounded).utc();
    startAnimation({
      mapId,
      start,
      end,
      interval: step * 60,
    });
  };

  const handleClose = (event) => {
    if (isAnimating) play(event.target.value);
    else setInterval(event.target.value);
    setAnchorEl(null);
  };

  return (
    <div id="timecontrol" className="toolbar noselect">
      <div className="playbar noselect">
        <ThemeProvider theme={theme}>
          <div id="playButton">
            <IconButton
              onClick={(): void => {
                if (isAnimating)
                  stopAnimation({
                    mapId,
                  });
                else play(interval);
              }}
            >
              {isAnimating ? <PauseIcon /> : <PlayIcon />}
            </IconButton>
          </div>
          <div id="stepButton">
            <Button aria-controls="step-menu" aria-haspopup="true" onClick={handleClick}>{(!interval ? 600 : interval)/60} min</Button>
            <Menu
              id="step-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} value="300">5 min</MenuItem>
              <MenuItem onClick={handleClose} value="600">10 min</MenuItem>
              <MenuItem onClick={handleClose} value="900">15 min</MenuItem>
              <MenuItem onClick={handleClose} value="1800">30 min</MenuItem>
              <MenuItem onClick={handleClose} value="3600">60 min</MenuItem>
            </Menu>
          </div>
          <div id="currentMapTime">
            {layer &&
              layer.dimensions &&
              layer.dimensions.length &&
              moment(layer.dimensions[0].currentValue).format('LT')}
            <div id="currentMapDate">
            {layer &&
              layer.dimensions &&
              layer.dimensions.length &&
              moment(layer.dimensions[0].currentValue).format('l')}
          </div>
        </div>
      </ThemeProvider>
    </div>
    </div>
  );
};

const ConnectedAnimationComponent = connect(
  (state, props) => ({
    isAnimating: mapSelectors.isAnimating(state, props.mapId),
    layers: mapSelectors.getMapLayers(state, props.mapId),
  }),
  {
    startAnimation: mapActions.mapStartAnimation,
    stopAnimation: mapActions.mapStopAnimation,
  },
)(AnimationComponent);

export default ConnectedAnimationComponent;
